export const GET_OPPORTUNITIES_URL = "/api/opportunity/single/";
export const POST_OPPORTUNITIES_URL = "/api/opportunity/single/";
export const PUT_OPPORTUNITIES_URL = "/api/opportunity/single/edit/";
export const PATCH_OPPORTUNITIES_URL = "/api/opportunity/single/edit/";
export const GET_OPPORTUNITIES_LIST_URL = "/api/opportunity/list/";
export const GET_OPTIONS_URL = "/api/opportunity/options/";
export const GET_USERS_URL = "/api/opportunity/users/";
export const USER_URL = "/api/opportunity/user/";
export const POST_ADMIN_EMAIL_NOTIFICATION_URL = "api/email/admin/";
export const POST_EDO_EMAIL_NOTIFICATION_URL = "api/email/edo/";
export const USER_TRACKING_URL = "/api/opportunity/user/tracking/";
