export const ADD_ALL = "ADD_ALL";
export const ADD_USER = "ADD_USER";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_COORDS = "ADD_COORDS";
export const ADD_PID = "ADD_PID";
export const ADD_GEOMETRY = "ADD_GEOMETRY";
export const ADD_PARCEL_OWNER = "ADD_PARCEL_OWNER";
export const ADD_PARCEL_SIZE = "ADD_PARCEL_SIZE";
export const ADD_SITE_ID = "ADD_SITE_ID";
export const ADD_BUSINESS_CONTACT = "ADD_BUSINESS_CONTACT";
export const ADD_BUSINESS_CONTACT_NAME = "ADD_BUSINESS_CONTACT_NAME";
export const ADD_BUSINESS_CONTACT_EMAIL = "ADD_BUSINESS_CONTACT_EMAIL";
export const ADD_APPROVAL_STATUS = "ADD_APPROVAL_STATUS";
export const ADD_PRIVATE_NOTE = "ADD_PRIVATE_NOTE";
export const ADD_PUBLIC_NOTE = "ADD_PUBLIC_NOTE";
export const ADD_RESOUCE_IDS = "ADD_RESOUCE_IDS";
export const ADD_NEARBY_RESOUCES = "ADD_NEARBY_RESOUCES";
export const ADD_SOIL = "ADD_SOIL";
export const ADD_ELEVATION = "ADD_ELEVATION";
export const RESET_OPPORTUNITY = "RESET_OPPORTUNITY";
export const ADD_SITE_INFO = "ADD_SITE_INFO";
export const ADD_USER_INFO = "ADD_USER_INFO";
export const ADD_RENTAL_PRICE = "ADD_RENTAL_PRICE";
export const ADD_SALE_PRICE = "ADD_SALE_PRICE";
export const ADD_NAME = "ADD_NAME";
export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_CAPACITY = "ADD_USER_INFO_CAPACITY";
export const ADD_LAST_ADMIN = "ADD_LAST_ADMIN";
